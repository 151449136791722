@font-face {
  font-family: 'Elalba';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Elalba/elalba-regular.ttf');
  src: local('Elalba'),
    url('./fonts/Elalba/elalba-regular.ttf') format('truetype'),
}

h1,
h2,
h3 {
  font-family: 'Elalba';

}

body {
  font: 100% Ubuntu, Verdana, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
